body {
  margin: 0;
  background-image: url("../public/img/bg.png");
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Lilita One';
  src: url('../public/LilitaOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: 'Lilita One', sans-serif;
}